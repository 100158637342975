


































import {Component, Prop, Vue} from 'vue-property-decorator';
import Company from '@/models/Company';

@Component({})
export default class CompanyTileComponent extends Vue {

    @Prop({default: []})
    private companies!: Company[];

    constructor() {
        super();
    }

    private onRowClicked(item: Company) {
        this.$router.push({name: 'companyDashboard', params: {companyId: item.id}});
    }
}
